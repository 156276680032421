import styled from "styled-components";

const Ticket = styled.svg`
    height: 49.25px;
    width: 49.25px;
`;

export default function ExamIcon() {
	return (
		<Ticket>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="50"
				height="51"
				viewBox="0 0 50 51"
				fill="none"
			>
				<circle cx="24.9331" cy="25.5532" r="24.6274" fill="white" />
				<path
					d="M24.6877 33.1809C26.7101 33.1809 28.4342 32.468 29.8601 31.0421C31.2851 29.6171 31.9975 27.8935 31.9975 25.8711C31.9975 23.8487 31.2851 22.1246 29.8601 20.6986C28.4342 19.2737 26.7101 18.5612 24.6877 18.5612C22.6653 18.5612 20.9416 19.2737 19.5167 20.6986C18.0908 22.1246 17.3778 23.8487 17.3778 25.8711C17.3778 27.8935 18.0908 29.6171 19.5167 31.0421C20.9416 32.468 22.6653 33.1809 24.6877 33.1809ZM25.4187 25.5787L27.6116 27.7716C27.7578 27.9178 27.8309 28.0884 27.8309 28.2833C27.8309 28.4783 27.7578 28.6488 27.6116 28.795C27.4654 28.9412 27.2949 29.0143 27.0999 29.0143C26.905 29.0143 26.7344 28.9412 26.5882 28.795L24.176 26.3828C24.1273 26.334 24.0542 26.1635 23.9567 25.8711V22.2162C23.9567 22.0212 24.0298 21.8507 24.176 21.7045C24.3222 21.5583 24.4928 21.4852 24.6877 21.4852C24.8826 21.4852 25.0532 21.5583 25.1994 21.7045C25.3456 21.8507 25.4187 22.0212 25.4187 22.2162V25.5787ZM24.6877 40.3811H24.3222C24.2004 40.3811 24.0907 40.3568 23.9933 40.308C20.8013 39.309 18.1697 37.329 16.0986 34.3681C14.0275 31.4081 12.9919 28.1371 12.9919 24.5553V17.6475C12.9919 17.0384 13.1688 16.4901 13.5226 16.0028C13.8755 15.5155 14.3321 15.1622 14.8925 14.9429L23.6643 11.6534C24.0054 11.5316 24.3466 11.4707 24.6877 11.4707C25.0288 11.4707 25.3699 11.5316 25.7111 11.6534L34.4829 14.9429C35.0433 15.1622 35.5004 15.5155 35.8542 16.0028C36.207 16.4901 36.3834 17.0384 36.3834 17.6475V24.5553C36.3834 28.1371 35.3479 31.4081 33.2768 34.3681C31.2056 37.329 28.5741 39.309 25.3821 40.308C25.2603 40.3568 25.0288 40.3811 24.6877 40.3811Z"
					fill="#A70909"
				/>
			</svg>
		</Ticket>
	);
}
