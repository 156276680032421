import React, { useState } from "react";
import { useAuth } from "../services/auth";
import { Container, Typography, TextField, Button } from "@mui/material";
import axios from "axios";

function Profile() {
	const { user, logout } = useAuth();
	const [name, setName] = useState(user.profile.name);
	const [language, setLanguage] = useState(user.profile.locale);

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			await axios.put(
				`${process.env.REACT_APP_API_URL}/api/users/profile`,
				{ name, language },
				{ headers: { Authorization: `Bearer ${user.access_token}` } },
			);
			alert("Profile updated successfully");
		} catch (error) {
			console.error("Error updating profile:", error);
			alert("Failed to update profile");
		}
	};

	return (
		<Container maxWidth="sm" style={{ marginTop: "2rem" }}>
			<Typography variant="h4" gutterBottom>
				Edit Profile
			</Typography>
			<form onSubmit={handleSubmit}>
				<TextField
					fullWidth
					margin="normal"
					label="Name"
					value={name}
					onChange={(e) => setName(e.target.value)}
				/>
				{/* <TextField
          fullWidth
          margin="normal"
          label="Preferred Language"
          value={language || ''}
          onChange={(e) => setLanguage(e.target.value)}
        /> */}
				<Button
					type="submit"
					variant="contained"
					color="primary"
					style={{ marginTop: "1rem" }}
				>
					Update Profile
				</Button>
			</form>
			<Button
				onClick={logout}
				variant="outlined"
				color="secondary"
				style={{ marginTop: "1rem" }}
			>
				Logout
			</Button>
		</Container>
	);
}

export default Profile;
