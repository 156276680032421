import styled from "styled-components";

const Ticket = styled.svg`
    height: 49.25px;
    width: 49.25px;
`;

export default function SlackIcon() {
	return (
		<Ticket>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="23"
				height="24"
				viewBox="0 0 23 24"
				fill="none"
			>
				<g clip-path="url(#clip0_987_749)">
					<path
						d="M8.44777 0.730469C7.20592 0.730469 6.19934 1.73931 6.19934 2.98342C6.19904 3.27899 6.25696 3.57172 6.3698 3.8449C6.48263 4.11808 6.64817 4.36636 6.85695 4.57557C7.06574 4.78477 7.31369 4.95081 7.58664 5.06419C7.85959 5.17757 8.15221 5.23608 8.44777 5.23638H10.6966V2.98342C10.6971 2.38647 10.4605 1.81376 10.0387 1.39126C9.617 0.968756 9.04473 0.731067 8.44777 0.730469ZM8.44777 6.73835H2.45156C1.20971 6.73835 0.203125 7.74719 0.203125 8.99168C0.203125 10.2358 1.20971 11.2446 2.45156 11.2446H8.44815C9.68963 11.2446 10.6966 10.2358 10.6966 8.99168C10.6966 7.74719 9.68963 6.73835 8.44777 6.73835Z"
						fill="#36C5F0"
					/>
					<path
						d="M22.6888 8.99168C22.6888 7.74719 21.6818 6.73835 20.44 6.73835C19.1981 6.73835 18.1915 7.74719 18.1915 8.99168V11.2446H20.44C21.0369 11.244 21.6092 11.0063 22.0309 10.5838C22.4526 10.1613 22.6893 9.58864 22.6888 8.99168ZM16.6926 8.99168V2.98342C16.6931 2.38647 16.4564 1.81376 16.0347 1.39126C15.613 0.968756 15.0407 0.731067 14.4438 0.730469C13.2019 0.730469 12.1953 1.73931 12.1953 2.98342V8.9913C12.1953 10.2362 13.2019 11.245 14.4438 11.245C15.0407 11.2444 15.613 11.0067 16.0347 10.5842C16.4564 10.1617 16.6931 9.58864 16.6926 8.99168Z"
						fill="#2EB67D"
					/>
					<path
						d="M14.4438 23.2603C15.0407 23.2597 15.613 23.022 16.0347 22.5995C16.4564 22.177 16.6931 21.6043 16.6926 21.0073C16.6931 20.4103 16.4564 19.8376 16.0347 19.4151C15.613 18.9926 15.0407 18.7549 14.4438 18.7544H12.1953V21.0073C12.1953 22.2514 13.2019 23.2603 14.4438 23.2603ZM14.4438 17.2524H20.4403C21.6818 17.2524 22.6888 16.2435 22.6888 14.999C22.6893 14.4021 22.4526 13.8294 22.0309 13.4069C21.6092 12.9844 21.0369 12.7467 20.44 12.7461H14.4438C13.2019 12.7461 12.1953 13.7549 12.1953 14.999C12.195 15.2946 12.2529 15.5873 12.3658 15.8605C12.4786 16.1337 12.6441 16.382 12.8529 16.5912C13.0617 16.8004 13.3097 16.9664 13.5826 17.0798C13.8556 17.1932 14.1482 17.2521 14.4438 17.2524Z"
						fill="#ECB22E"
					/>
					<path
						d="M0.203126 14.999C0.202829 15.2946 0.260752 15.5873 0.373586 15.8605C0.48642 16.1337 0.651956 16.382 0.860743 16.5912C1.06953 16.8004 1.31748 16.9664 1.59043 17.0798C1.86339 17.1932 2.156 17.2517 2.45156 17.252C3.04852 17.2514 3.6208 17.0137 4.04252 16.5912C4.46424 16.1687 4.70088 15.596 4.70038 14.999V12.7461H2.45156C1.20971 12.7461 0.203126 13.7549 0.203126 14.999ZM6.19934 14.999V21.0069C6.19934 22.2514 7.20592 23.2603 8.44778 23.2603C9.04473 23.2597 9.61701 23.022 10.0387 22.5995C10.4605 22.177 10.6971 21.6043 10.6966 21.0073V14.999C10.6969 14.7035 10.639 14.4107 10.5261 14.1375C10.4132 13.8643 10.2477 13.616 10.0388 13.4068C9.83001 13.1976 9.58202 13.0315 9.30902 12.9182C9.03603 12.8048 8.74337 12.7463 8.44778 12.7461C7.20592 12.7461 6.19934 13.7549 6.19934 14.999Z"
						fill="#E01E5A"
					/>
				</g>
				<defs>
					<clipPath id="clip0_987_749">
						<rect
							width="22.4859"
							height="22.5303"
							fill="white"
							transform="translate(0.203125 0.730469)"
						/>
					</clipPath>
				</defs>
			</svg>
		</Ticket>
	);
}
