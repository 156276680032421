import {
	ServiceNameWrapper,
	ServiceContainer,
	ServiceItem,
	ServiceTitle,
	Subheader,
	TheBox,
} from "./styledComponents/ServiceBox.styled";
import React, { useEffect, useState } from "react";
import { useAuth } from "../services/auth";
import axios from "axios";
import { CircularProgress, Alert } from "@mui/material";
import AcademyIcon from "../assets/AcademyIcon";
import InteractionArrow from "../assets/interaction-arrow";
import LocationManagementIcon from "../assets/location-management";
import NotionIcon from "../assets/notion";
import SlackIcon from "../assets/slack";
import EnergyManagementIcon from "../assets/energy-management";
import ExamIcon from "../assets/examination-app";
import ComponentBuilderIcon from "../assets/component-builder";
import SupportTicket from "./../assets/SupportTicket";

export default function ServiceBox() {
	const { user } = useAuth();
	// const [services, setServices] = useState([]);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const API_URL = process.env.REACT_APP_API_URL;
	const [regServices, setRegServices] = useState([]);
	const [devServices, setDevServices] = useState([]);
	const [specialServices, setSpecialServices] = useState([]);

	useEffect(() => {
		const fetchServices = async () => {
			if (!user) return;

			setLoading(true);
			try {
				const response = await axios.get(`${API_URL}/api/services`, {
					headers: { Authorization: `Bearer ${user.access_token}` },
				});
				const allServices = response.data;

				// Extract user's tenant ID
				const userTenantId = user["urn:zitadel:iam:user:resourceowner:id"];

				// Map through services to determine the correct URL
				const servicesWithUrls = allServices.map((service) => {
					const tenantService = service.Tenants.find(
						(tenant) => tenant.organization_id === userTenantId,
					)?.TenantService;
					return {
						...service,
						url: tenantService?.url || service.default_url, // Use tenant-specific URL if available
					};
				});

				//Suche alle Kundenservices und sortiere Energiemgt. und Standort an den Anfang.
				const sortedServices = servicesWithUrls
					.filter((svc) => svc.groups[0] === undefined)
					.sort((a, b) => {
						if ((a.id === 20) | (a.id === 21)) {
							return -1;
						}
						if ((a.id !== 20) | (a.id !== 21)) {
							return 1;
						}
						if ((b.id === 20) | (b.id === 21)) {
							return 1;
						}
						if ((b.id !== 20) | (b.id !== 21)) {
							return -1;
						}
						return 0;
					});

				console.log("all svc", servicesWithUrls);

				setRegServices(sortedServices);

				setDevServices(
					servicesWithUrls.filter(
						(svc) =>
							(svc.groups[0] === "Tools") | (svc.groups[0] === "Development"),
					),
				);

				setSpecialServices(
					servicesWithUrls.filter((svc) => svc.groups[0] === "Dies und Das"),
				);
			} catch (error) {
				console.error("Error fetching services:", error);
				setError("Failed to fetch services.");
			} finally {
				setLoading(false);
			}
		};

		fetchServices();
	}, [user, API_URL]);

	const handleServiceClick = (service) => {
		let url = service.url;
		// Add protocol if missing
		if (!/^https?:\/\//i.test(url)) {
			url = `https://${url}`;
		}
		window.location.href = url; // Open in the same window
	};

	if (loading) return <CircularProgress />;
	if (error) return <Alert severity="error">{error}</Alert>;

	return (
		<TheBox>
			<ServiceContainer>
				{regServices.length > 0 ? (
					regServices.map((service) => (
						<React.Fragment key={service.id}>
							<ServiceItem onClick={() => handleServiceClick(service)}>
								<InteractionArrow class="arrow" />
								<ServiceNameWrapper>
									{(() => {
										switch (service.displayName) {
											case "Energiemanagementsystem":
												return <EnergyManagementIcon />;
											case "Akademie":
												return <AcademyIcon />;
											case "Prüfapp":
												return <ExamIcon />;
											case "Standortmanagement":
												return <LocationManagementIcon />;
											// case "Projektdateien":
											// 	return </>;
											case "Ticketsystem - Support":
												return <SupportTicket />;
											// case "Usermanagement - Zitadel":
											// 	return </>;
											// case "Datenmanagement":
											// 	return </>;
											default:
												return <ComponentBuilderIcon />;
										}
									})()}

									<ServiceTitle>
										{service.displayName === "Energiemanagementsystem"
											? "Energiemanagement"
											: service.displayName}
									</ServiceTitle>
								</ServiceNameWrapper>
								{/* <ServiceDescription>{service.description}</ServiceDescription> */}
							</ServiceItem>
						</React.Fragment>
					))
				) : (
					<p>Leider keine Services gefunden</p>
				)}
			</ServiceContainer>
			{devServices.length > 0 ? (
				<>
					<Subheader>Tools für Developer &hearts;</Subheader>
					<ServiceContainer>
						{devServices.map((service) => (
							<ServiceItem
								key={service.id}
								onClick={() => handleServiceClick(service)}
							>
								<InteractionArrow className="arrow" />
								<ServiceNameWrapper>
									{(() => {
										switch (service.displayName) {
											case "Slack":
												return <EnergyManagementIcon />;
											case "Notion ":
												return <AcademyIcon />;
											case "Prüfapp":
												return <ExamIcon />;
											case "Standortmanagement":
												return <LocationManagementIcon />;
											// case "Projektdateien":
											// 	return </>;
											case "Ticketsystem - Support":
												return <SupportTicket />;
											// case "Usermanagement - Zitadel":
											// 	return </>;
											// case "Datenmanagement":
											// 	return </>;
											default:
												return <ComponentBuilderIcon />;
										}
									})()}
									<ServiceTitle>{service.displayName}</ServiceTitle>
								</ServiceNameWrapper>
								{/* <ServiceDescription>{service.description}</ServiceDescription> */}
							</ServiceItem>
						))}
					</ServiceContainer>
				</>
			) : null}
			{specialServices.length > 0 ? (
				<>
					<Subheader>🍕</Subheader>
					<ServiceContainer>
						{specialServices.map((service) => (
							<ServiceItem
								key={service.id}
								onClick={() => handleServiceClick(service)}
							>
								<InteractionArrow className="arrow" />
								<ServiceNameWrapper>
									<AcademyIcon />
									<ServiceTitle>{service.displayName}</ServiceTitle>
								</ServiceNameWrapper>
								{/* <ServiceDescription>{service.description}</ServiceDescription> */}
							</ServiceItem>
						))}
					</ServiceContainer>
				</>
			) : null}
		</TheBox>
	);
}
