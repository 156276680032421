import styled from "styled-components";

const IconWrapper = styled.div`
	/* max-width: 51px;

	@media only screen and (min-width: 400px) {
		max-width: 25px;
	} */
`;
export default function AcademyIcon() {
	return (
		<IconWrapper>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="50"
				height="51"
				viewBox="0 0 50 51"
				fill="none"
				role="img"
				title=""
			>
				<title>Icon indicating to the Buildlinx Acedemy</title>
				<circle cx="24.7356" cy="25.4926" r="24.6274" fill="white" />
				<path
					d="M24.783 13.1797L9.20947 21.4245L24.783 29.6693L37.525 22.9223V32.4176H40.3566V21.4245M14.8726 27.1684V32.665L24.783 37.9142L34.6935 32.665V27.1684L24.783 32.4176L14.8726 27.1684Z"
					fill="black"
				/>
			</svg>
		</IconWrapper>
	);
}
