import {
	FooterBar,
	LinkWrapper,
	Rights,
	LastLine,
	Credentials,
} from "./styledComponents/Footer.styled";

export function Footer() {
	return (
		<FooterBar>
			<LastLine>
				<Rights>Alle Rechte vorbehalten.</Rights>
				<LinkWrapper>
					<Credentials href="https://www.buildlinx.de/impressum">
						Impressum
					</Credentials>
					<Credentials
						href="https://www.buildlinx.de/datenschutz"
						style={{
							marginLeft: "15px",
						}}
					>
						Datenschutz
					</Credentials>
				</LinkWrapper>
			</LastLine>
		</FooterBar>
	);
}
