import styled from "styled-components";

const IconWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;
`;

export default function InteractionIcon() {
	return (
		<IconWrapper>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="12"
				height="12"
				viewBox="0 0 12 12"
				fill="none"
			>
				<title>Interaction Arrow</title>
				<path
					d="M1.83202 11.5261L0.657715 10.3496L8.71013 2.28226H1.49651V0.601562H11.562V10.6857H9.88444V3.45874L1.83202 11.5261Z"
					fill="white"
				/>
			</svg>
		</IconWrapper>
	);
}
