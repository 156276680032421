// src/App.js
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { AuthProvider } from "./services/auth";
import Header from "./components/Header";
// import Login from './pages/Login';
import Callback from "./pages/Callback";
import Overview from "./pages/Overview";
import Profile from "./pages/Profile";
import AdminInterface from "./pages/AdminInterface";
import PrivateRoute from "./components/PrivateRoute";
import Unauthorized from "./pages/Unauthorized";

function AppContent() {
	return (
		<div className="App">
			<Header />
			<Routes>
				{/* <Route path="/login" element={<Login />} /> */}
				<Route path="/callback" element={<Callback />} />
				<Route
					path="/overview"
					element={
						<PrivateRoute>
							<Overview />
						</PrivateRoute>
					}
				/>
				<Route
					path="/profile"
					element={
						<PrivateRoute>
							<Profile />
						</PrivateRoute>
					}
				/>
				<Route
					path="/admin"
					element={
						<PrivateRoute requiredRole="buildlinx-admin">
							<AdminInterface />
						</PrivateRoute>
					}
				/>
				<Route
					path="/"
					element={
						<PrivateRoute>
							<Overview />
						</PrivateRoute>
					}
				/>
				<Route path="/unauthorized" element={<Unauthorized />} />
			</Routes>
		</div>
	);
}

function App() {
	return (
		<AuthProvider>
			<Router>
				<AppContent />
			</Router>
		</AuthProvider>
	);
}

export default App;
