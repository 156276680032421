import styled from "styled-components";

export const OverviewContainer = styled.div`
  display: flex;
  justify-content: center;
  background-color: #030D1A;
  min-width: max-content;
  min-height: 100vh;

  @font-face {
    font-family: "DIN2014-Regular";
    src: local("DIN2014-Regular"), url(fonts/DIN2014-Regular.otf) format("otf");
  }

  font-family: "DIN2014-Regular";
`;
