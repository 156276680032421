import styled from "styled-components";

const Ticket = styled.svg`
    height: 49.25px;
    width: 49.25px;
`;

export default function ComponentBuilderIcon() {
	return (
		<Ticket>
			<svg
				width="50"
				height="51"
				viewBox="0 0 100 100"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<circle cx="46" cy="46" r="46" fill="white" />
				<path
					d="M61.1663 56.832C63.788 56.832 67.6663 55.0987 67.6663 50.332C67.6663 45.5654 63.788 43.832 61.1663 43.832H56.833V39.4987H61.1663C65.933 39.4987 67.6663 35.6204 67.6663 32.9987C67.6663 28.232 63.788 26.4987 61.1663 26.4987H56.833V24.332H54.6663V26.4987L37.333 26.4987V24.332H35.1663V26.4987H24.333V30.832H35.1663V35.1654H30.833C28.2113 35.1654 24.333 36.8987 24.333 41.6654C24.333 46.432 28.2113 48.1654 30.833 48.1654H35.1663V52.4987H30.833C28.2113 52.4987 24.333 54.232 24.333 58.9987C24.333 63.7654 28.2113 65.4987 30.833 65.4987H35.1663L35.1663 67.6654H37.333L37.333 65.4987H54.6663V67.6654H56.833V65.4987H67.6663V61.1654L56.833 61.1654L56.833 56.832L61.1663 56.832ZM61.1663 48.1654C62.1413 48.1654 63.333 48.577 63.333 50.332C63.333 52.087 62.1413 52.4987 61.1663 52.4987H56.833V48.1654H61.1663ZM54.6663 43.832H37.333V39.4987H54.6663V43.832ZM61.1663 30.832C62.1413 30.832 63.333 31.2654 63.333 32.9987C63.333 33.9737 62.9213 35.1654 61.1663 35.1654H56.833V30.832H61.1663ZM37.333 30.832L54.6663 30.832V35.1654H37.333V30.832ZM30.833 43.832C29.858 43.832 28.6663 43.4204 28.6663 41.6654C28.6663 39.9104 29.858 39.4987 30.833 39.4987H35.1663V43.832H30.833ZM37.333 48.1654H54.6663V52.4987H37.333V48.1654ZM30.833 61.1654C29.858 61.1654 28.6663 60.7537 28.6663 58.9987C28.6663 57.2437 29.858 56.832 30.833 56.832H35.1663V61.1654H30.833ZM54.6663 61.1654H37.333V56.832H54.6663V61.1654Z"
					fill="black"
				/>
			</svg>
		</Ticket>
	);
}
