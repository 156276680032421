import styled from "styled-components";

const Ticket = styled.svg`
    height: 49.25px;
    width: 49.25px;
`;

export default function EnergyManagementIcon() {
	return (
		<Ticket>
			<svg
				width="50"
				height="51"
				viewBox="0 0 92 92"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<rect width="92" height="92" rx="46" fill="#51A038" />
				<path
					d="M43.0739 54.7794H28.4429L48.9263 13.8125V37.2222H63.5574L43.0739 78.1891V54.7794Z"
					fill="white"
				/>
			</svg>
		</Ticket>
	);
}
