import React from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../services/auth";
import { Avatar, Menu, MenuItem, IconButton } from "@mui/material";
import {
	Banner,
	ShellBar,
	ShellLink,
	Wrapper,
	BlxLogo,
	HubIcon,
	HeadlineContainer,
	Headline,
	Subheader,
	HubColored,
	AdminLink,
	IconAndHeader,
	StyledUser,
} from "./styledComponents/Header.styled.js";
import logo from "../assets/logo.png";
import { ReactComponent as Apps } from "./../assets/apps.svg";

const Navigation = () => {
	const { user, roles, logout } = useAuth();
	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleMenuOpen = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleMenuClose = () => {
		setAnchorEl(null);
	};

	const handleLogout = () => {
		handleMenuClose();
		logout();
	};

	const getInitials = (name) => {
		const initials = name
			.split(" ")
			.map((part) => part[0])
			.join("");
		return initials.toUpperCase();
	};

	const profilePicture = user?.profile?.picture;
	let userName = "";
	if (!user?.profile?.name) {
		userName = "";
	} else {
		userName = user?.profile?.name || "User";
	}

	const hasAdminAccess = roles.includes("buildlinx-admin");

	return (
		<>
			<ShellBar>
				<ShellLink href="/">
					<BlxLogo src={logo} alt="Buildlinx logo" />
				</ShellLink>

				<Wrapper>
					{hasAdminAccess && <AdminLink to="/admin">Admin Interface</AdminLink>}
					<IconButton onClick={handleMenuOpen}>
						<Avatar src={profilePicture} alt={userName}>
							{!profilePicture && getInitials(userName)}
						</Avatar>
					</IconButton>
				</Wrapper>

				<Menu
					anchorEl={anchorEl}
					open={Boolean(anchorEl)}
					onClose={handleMenuClose}
				>
					<MenuItem component={Link} to="/profile" onClick={handleMenuClose}>
						Edit Profile
					</MenuItem>
					<MenuItem onClick={handleLogout}>Logout</MenuItem>
				</Menu>
			</ShellBar>

			<Banner>
				<IconAndHeader>
					<HubIcon>
						<Apps />
					</HubIcon>

					<HeadlineContainer>
						<Headline>
							Buildlinx
							<HubColored>App Hub</HubColored>
						</Headline>
						<Subheader>
							Willkommen <StyledUser>{userName}</StyledUser>, hier finden Sie
							ihre Apps
						</Subheader>
					</HeadlineContainer>
				</IconAndHeader>
			</Banner>
		</>
	);
};

export default Navigation;
