import styled from "styled-components";

export const FooterBar = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 0 10% 0 10%;
    background-color: #030D1A;
	color: #ffffff80;
    height: 20vh;
`;

export const LinkWrapper = styled.div`
    height: fit-content;
`;

export const Rights = styled.p`
    text-decoration: none;
	font-family: "DIN2014-Light";
`;

export const LastLine = styled.div`
    display: flex;
    justify-content: space-between  ;
    align-items: center;
    margin: 15px;
    width: 100%;
`;

export const Credentials = styled.a`
	text-decoration: none;
	color: #ffffff80;
	font-family: "DIN2014-Light";

    &:hover {
        color: #4deef2;
    }
`;
