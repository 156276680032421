import ServiceBox from "../components/ServiceBox";
import { OverviewContainer } from "../components/styledComponents/Overview.styled";
import { Footer } from "../components/Footer";

const Overview = () => {
	return (
		<>
			<OverviewContainer>
				<ServiceBox />
			</OverviewContainer>
			<Footer />
		</>
	);
};

export default Overview;
