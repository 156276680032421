import styled from "styled-components";
import img from "./../../assets/bannerImage.png";
import { Link } from "react-router-dom";

// MENUE BAR HERE

export const ShellBar = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 52px;
    width: 100%;
    padding: 4px 0 4px 0;
    background-color: #1B1B1B;
    color: white;
`;

export const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: fit-content;
    margin: 0 3% 0 0;
`;

export const ShellLink = styled.a`
    text-decoration: none;
    color: white;
    margin-left: 3%;
`;

export const AdminLink = styled(Link)`
    color: white;
    font-family: "Inter", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-size: 18px;
    text-decoration: none;
    margin-right: 15px;
    padding: 6px 11px 6px 11px;
    border: 1px solid #373B3C;
    border-radius: 6px;

    &:hover{
        background-color: rgb(255, 255, 255, 0.04);
    }
`;

export const BlxLogo = styled.img`
    max-height: 32px;
`;

export const StyledUser = styled.span`
    font-family: "DIN2014-Bold";
`;

// BANNER HERE

export const Banner = styled.div`
    display: flex; 
    justify-content: center;
    align-items: center;
    padding: 10px 0 10px 0;
    height: 235px;
    width: 100%;
    background-image: url(${img});
    background-color: #030D1A;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

`;

export const IconAndHeader = styled.div`
    display: flex;
    flex-direction: row;
    width: calc(322px * 3 + 32px * 2 + 11.5px * 2);
`;

export const HubIcon = styled.div`
    max-width: 79px;
    max-height: 74px;
`;

export const HeadlineContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    max-height: 106px;
    color: white;
    margin-left: 3%;
`;

export const Headline = styled.div`
    font-family: "DIN2014-Bold";
    margin: 0;
    font-size: 3rem;
    letter-spacing: -0.01em;
    text-align: center;
    text-wrap: nowrap;
`;

export const Subheader = styled.p`
    font-family: "DIN2014-Regular";
    max-height: 20px;
    margin: 0;
    font-size: 1rem;
    text-wrap: nowrap;
`;

export const HubColored = styled.span`
    color: #34e1eb;
    margin-left: 4px;
    font-size: 3rem;
    letter-spacing: -0.01em;
    text-align: center;

`;
