import React, { useEffect, useState, useMemo } from 'react';
import { useAuth } from '../services/auth';
import axios from 'axios';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Alert,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem as DropdownMenuItem,
  Checkbox,
  FormControlLabel,
  Typography,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';

const ServicesTab = () => {
  const { user } = useAuth();
  const [services, setServices] = useState([]);
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [currentService, setCurrentService] = useState({ 
    name: '', 
    displayName: '', 
    description: '', 
    default_url: '', 
    requiredRoleId: '', 
    visible_to_all: false,
    icon: null,
    groups: [] // Add groups field
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedService, setSelectedService] = useState(null);
  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    fetchServices();
    fetchRoles();
  }, [user, API_URL]);

  const fetchServices = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${API_URL}/api/services`, {
        headers: { Authorization: `Bearer ${user.access_token}` },
      });
      console.log('Fetched services:', response.data);
      setServices(response.data);
    } catch (error) {
      console.error('Error fetching services:', error);
      setError('Failed to fetch services.');
    } finally {
      setLoading(false);
    }
  };

  const fetchRoles = async () => {
    try {
      const response = await axios.get(`${API_URL}/api/roles`, {
        headers: { Authorization: `Bearer ${user.access_token}` },
      });
      setRoles(response.data);
    } catch (error) {
      console.error('Error fetching roles:', error);
      setError('Failed to fetch roles.');
    }
  };

  const handleAdd = () => {
    setCurrentService({ name: '', displayName: '', description: '', default_url: '', requiredRoleId: '', visible_to_all: false, icon: null, groups: [] });
    setDialogOpen(true);
  };

  const handleEdit = (service) => {
    setCurrentService({
      ...service,
      requiredRoleId: service.requiredRole ? service.requiredRole.id : '',
      groups: service.groups || []
    });
    setDialogOpen(true);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${API_URL}/api/services/${id}`, {
        headers: { Authorization: `Bearer ${user.access_token}` },
      });
      setServices(services.filter(service => service.id !== id));
    } catch (error) {
      console.error('Error deleting service:', error);
      setError('Failed to delete service.');
    }
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleDialogSave = async () => {
    try {
      const formData = new FormData();
      formData.append('name', currentService.name);
      formData.append('displayName', currentService.displayName);
      formData.append('description', currentService.description);
      formData.append('default_url', currentService.default_url);
      formData.append('requiredRoleId', currentService.requiredRoleId || '');
      formData.append('visible_to_all', currentService.visible_to_all);
      formData.append('groups', JSON.stringify(currentService.groups)); // Add groups to formData
      if (currentService.icon) {
        formData.append('icon', currentService.icon);
      }

      const config = {
        headers: {
          Authorization: `Bearer ${user.access_token}`,
          'Content-Type': 'multipart/form-data'
        }
      };

      if (currentService.id) {
        await axios.put(`${API_URL}/api/services/${currentService.id}`, formData, config);
      } else {
        await axios.post(`${API_URL}/api/services`, formData, config);
      }
      setDialogOpen(false);
      fetchServices();
    } catch (error) {
      console.error('Error saving service:', error);
      setError('Failed to save service.');
    }
  };

  const handleMenuOpen = (event, service) => {
    setAnchorEl(event.currentTarget);
    setSelectedService(service);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedService(null);
  };

  const handleAddGroup = () => {
    setCurrentService({
      ...currentService,
      groups: [...currentService.groups, '']
    });
  };

  const handleGroupChange = (index, value) => {
    const newGroups = [...currentService.groups];
    newGroups[index] = value;
    setCurrentService({
      ...currentService,
      groups: newGroups
    });
  };

  const handleDeleteGroup = (index) => {
    const newGroups = currentService.groups.filter((_, i) => i !== index);
    setCurrentService({
      ...currentService,
      groups: newGroups
    });
  };

  const tableRows = useMemo(() => {
    return services.map(service => (
      <TableRow key={service.id}>
        <TableCell>{service.displayName || service.name || 'N/A'}</TableCell>
        <TableCell>{service.default_url || 'N/A'}</TableCell>
        <TableCell>{service.visible_to_all ? 'Yes' : 'No'}</TableCell>
        <TableCell>
          {service.requiredRole ? service.requiredRole.name : 'None'}
        </TableCell>
        <TableCell>{service.groups ? service.groups.join(', ') : 'N/A'}</TableCell>
        <TableCell>
          <IconButton onClick={(event) => handleMenuOpen(event, service)}>
            <MoreVertIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    ));
  }, [services]);

  if (loading) return <CircularProgress />;
  if (error) return <Alert severity="error">{error}</Alert>;

  return (
    <div>
      <h2>Services</h2>
      <Button variant="contained" onClick={handleAdd}>Add Service</Button>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Display Name</TableCell>
              <TableCell>Default URL</TableCell>
              <TableCell>Visible to All</TableCell>
              <TableCell>Required Role</TableCell>
              <TableCell>Groups</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {services.length > 0 ? tableRows : (
              <TableRow>
                <TableCell colSpan={6}>No services found</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={() => { handleEdit(selectedService); handleMenuClose(); }}>Edit</MenuItem>
        <MenuItem onClick={() => { handleDelete(selectedService.id); handleMenuClose(); }}>Delete</MenuItem>
      </Menu>

      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle>{currentService.id ? 'Edit Service' : 'Add Service'}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Name"
            type="text"
            fullWidth
            value={currentService.name}
            onChange={(e) => setCurrentService({ ...currentService, name: e.target.value })}
          />
          <TextField
            margin="dense"
            label="Display Name"
            type="text"
            fullWidth
            value={currentService.displayName}
            onChange={(e) => setCurrentService({ ...currentService, displayName: e.target.value })}
          />
          <TextField
            margin="dense"
            label="Description"
            type="text"
            fullWidth
            multiline
            rows={4}
            value={currentService.description}
            onChange={(e) => setCurrentService({ ...currentService, description: e.target.value })}
          />
          <TextField
            margin="dense"
            label="Default URL"
            type="text"
            fullWidth
            value={currentService.default_url}
            onChange={(e) => setCurrentService({ ...currentService, default_url: e.target.value })}
          />
          <FormControl fullWidth margin="dense">
            <InputLabel>Required Role</InputLabel>
            <Select
              value={currentService.requiredRoleId}
              onChange={(e) => setCurrentService({ ...currentService, requiredRoleId: e.target.value })}
            >
              <DropdownMenuItem value="">
                <em>None</em>
              </DropdownMenuItem>
              {roles.map(role => (
                <DropdownMenuItem key={role.id} value={role.id}>
                  {role.name}
                </DropdownMenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControlLabel
            control={
              <Checkbox
                checked={currentService.visible_to_all}
                onChange={(e) => setCurrentService({ ...currentService, visible_to_all: e.target.checked })}
              />
            }
            label="Visible to All Tenants"
          />
          <input
            type="file"
            onChange={(e) => setCurrentService({ ...currentService, icon: e.target.files[0] })}
          />
          
          <Typography variant="h6" style={{ marginTop: '20px' }}>Groups</Typography>
          {currentService.groups.map((group, index) => (
            <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
              <TextField
                value={group}
                onChange={(e) => handleGroupChange(index, e.target.value)}
                style={{ marginRight: '10px' }}
              />
              <IconButton onClick={() => handleDeleteGroup(index)}>
                <DeleteIcon />
              </IconButton>
            </div>
          ))}
          <Button onClick={handleAddGroup}>
            Add Group
          </Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <Button onClick={handleDialogSave}>Save</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ServicesTab;