import styled from "styled-components";

export const TheBox = styled.div`
    display: flex;
    flex-direction: column;
`;

export const ServiceContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    /* max-width: 1200px; */
    width: auto;
    gap: 11.49px;
    padding-top: 30px;
    padding-bottom: 50px;

    @media only screen and (max-width: 1150px) {
        grid-template-columns: 1fr 1fr;
    }

    @media only screen and (max-width: 730px) {
        grid-template-columns: 1fr;
    }
`;

export const ServiceItem = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    justify-self: stretch;
    align-self: stretch;
    max-width: 312px;
    color: white;
    background-color: #0E1F31;
    background-image: radial-gradient(49.85% 49.85% at 50.06% 0%, rgba(106, 198, 198, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%);
    border: 1.03px solid #373B3C;
    border-radius: 9.979px; 
    padding: 15px 15px 0 15px;
    font-size: clamp(0.5rem, 1vw, 2rem);
    line-height: 20px;
    overflow-wrap: anywhere;

    @media only screen and (min-width: 1200px ){
        min-height: 132px;
    }

    &:hover{
        cursor: pointer;
        border-color: rgba(77, 237, 242, 0.6);
        box-shadow: 0 2px 20px rgba(117, 84, 240, .25);
    }
`;

export const ServiceTitle = styled.h4`
    margin: 0;
    font-size: clamp(0.8rem, 1.1rem, 1.3rem);
    line-height: 18px;
    max-width: 71%;
    font-family: "DIN2014-Regular";

    @media only screen and (max-width: 400px) {
        line-height: 20px;
    }
    text-wrap: balance;
`;

export const ServiceNameWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;
    gap: 20px;
`;

export const Subheader = styled.h2`
    font-family: "DIN2014-Bold";
    color: white;
`;
