import styled from "styled-components";

const Ticket = styled.svg`
    height: 49.25px;
    width: 49.25px;
`;

export default function LocationManagementIcon() {
	return (
		<Ticket>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="50"
				height="51"
				viewBox="0 0 50 51"
				fill="none"
			>
				<circle cx="25.2221" cy="25.5141" r="24.6274" fill="white" />
				<path
					d="M31.7411 27.6026H34.3487V31.6325H38.2602V34.3192H34.3487V38.3491H31.7411V34.3192H27.8297V31.6325H31.7411V27.6026ZM25.2221 12.8262L38.2602 24.916H33.0449C31.7936 24.9168 30.5607 25.2267 29.4495 25.8196C28.3384 26.4125 27.3813 27.2711 26.6586 28.3236C25.9359 29.3761 25.4685 30.5917 25.2957 31.8686C25.1229 33.1455 25.2497 34.4465 25.6654 35.6625H16.0955V24.916H12.1841L25.2221 12.8262Z"
					fill="#437F8C"
				/>
			</svg>
		</Ticket>
	);
}
