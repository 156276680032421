import styled from "styled-components";

const Ticket = styled.svg`
    height: 49.25px;
    width: 49.25px;
`;

export default function SupportTicketIcon() {
	return (
		<Ticket>
			<svg
				width="100%"
				height="100%"
				version="1.1"
				viewBox="0 0 100 100"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="m100 50c0 27.613-22.387 50-50 50s-50-22.387-50-50 22.387-50 50-50 50 22.387 50 50"
					fill="#fff"
				/>
				<path
					d="m78.781 38.539-3.7148-3.7148c-0.50391-0.50391-1.3867-0.62891-2.0156-0.25-2.2031 1.3867-4.9766 1.0703-6.8008-0.75391-1.8281-1.8281-2.1406-4.6602-0.75391-6.8008 0.37891-0.62891 0.31641-1.4492-0.25-2.0156l-3.7148-3.7148c-1.5117-1.5117-4.0312-1.5117-5.543 0l-9.4453 9.4453 3.9062 3.9062-2.2656 2.2656-3.9062-3.9062-22.984 22.984c-1.5117 1.5117-1.5117 4.0312 0 5.543l3.7148 3.7148c0.50391 0.50391 1.3242 0.62891 1.9531 0.25 2.1406-1.3242 4.9141-0.94531 6.7383 0.82031 1.7617 1.7617 2.1406 4.5352 0.82031 6.7383-0.37891 0.62891-0.31641 1.4492 0.25 1.9531l3.7773 3.7773c0.75391 0.75391 1.7617 1.1328 2.7695 1.1328 1.0078 0 2.0156-0.37891 2.7695-1.1328l22.984-22.984-3.9062-3.9062 2.2656-2.2656 3.9062 3.9062 9.4453-9.4453c1.5117-1.5156 1.5117-4.0352 0-5.5469zm-28.465 0.56641 2.2656-2.2656 4.2188 4.2188-2.2656 2.2656zm10.578 10.578-4.2188-4.2188 2.2656-2.2656 4.2188 4.2188z"
					fill="#4c25e0"
				/>
			</svg>
		</Ticket>
	);
}
